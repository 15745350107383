/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import { createApp } from 'vue';

import ManageFields from './vue_components/ManageFields.vue';
import ValuesAdd from './vue_components/ValuesAdd.vue';
import DataSourceFields from './vue_components/DataSourceFields.vue';

require('./bootstrap');

window.vue = {
    createApp,
    components: {
        DataSourceFields,
        ManageFields,
        ValuesAdd,
    },
};

// Vue is current only used on some views and not app wide - so it is only enabled on those views
