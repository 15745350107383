<template>
    <div ref="templateContainer">
        <draggable
            :list="listData"
            @end="onDrop"
            ghost-class="ghost"
            handle=".item_dragger"
            @start="drag = true"
            item-key="key"
        >
            <template v-slot:item="itemInfo">
                <div class="input-group margin-top-10" :key="itemInfo.element.index">
                    <span class="input-group-addon">
                        <span class="glyphicon glyphicon-move dragger item_dragger"></span>
                    </span>
                    <input type="text" class="form-control" v-model="itemInfo.element.display"/>
                    <span class="input-group-btn">
                        <a class="btn-danger btn" @click="removeRow(itemInfo.index)">
                            <i class="fas fa-trash" aria-hidden="true"></i>
                        </a>
                    </span>
                </div>
            </template>
        </draggable>

        <div class="margin-top-20">
            <a @click="addRow" href="#" class="blue">
                <i class="fas fa-plus" aria-hidden="true"></i> Add New Value
            </a>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import _ from 'lodash';
import { inject } from 'vue';

export default {
    components: { draggable },
    setup() {
        return { data: inject('data') };
    },
    data() {
        const listData = this.data || [];

        return {
            listData,
        };
    },
    /**
     * Add a listener to ensure the list-data-changed event is triggered before save (the save button is outside the template).
     * This seems unusual, but it's important to not trigger the event unnecessarily to avoid UI issues.
     * Note that the following alternate methods cause problems:
     * watch listData:
     *    Using a watch handler would emit list-data-changed on every keystroke. This results in loss of focus on the
     *    current input element after every keystroke for newly added elements. Unsure why.
     * @blur handler on the input:
     *    Works mostly but causes issues when you try to drag and drop an element immediately after editing it:
     *    You will need to click the dragger twice to actually drag it.
     * @blur handler on the container:
     *    Will not work.
     * @focusout handler on the container:
     *    A quick shot at this didn't produce the desired results. Might be possible though.
     */
    mounted() {
        if ($('#data-source-edit-form').attr('data-unsaved-values') === 'true') {
            this.disableCsvButtons();
        };
        document.addEventListener('click', this.handleClickOutsideTemplate, true);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutsideTemplate, true);
    },
    methods: {
        // Actions
        addRow(e) {
            e.preventDefault();

            const key = _.uniqueId();
            this.listData.push({ key: key, display: '', new: true });
            this.onChangeOccurred();
        },
        onDrop(event) {
            if (event.newIndex !== event.oldIndex) {
                this.onChangeOccurred();
            }
        },
        removeRow(index) {
            if (index !== -1) {
                this.listData.splice(index, 1);
            } else {
                console.warn(`Cannot find key ${key}`);
            }
            this.onChangeOccurred();
        },
        onChangeOccurred() {
            // Disable the CSV buttons on unsaved data (bug 50467).
            this.disableCsvButtons();

        },
        disableCsvButtons() {
            console.log('Disabling csv buttons due to unsaved changes.');
            $('.btn-ds-csv').addClass('disabled')
            const $container = $('.btn-ds-csv').parent();
            const disabledNoticeExists = $container.find('.disabled-notice').length > 0;
            if (!disabledNoticeExists) {
                $container.prepend('<div class="disabled-notice text-right text-muted">CSV functions are disabled because of unsaved values. Please save the data source configuration.</div>');
            }
        },
        handleClickOutsideTemplate(event) {
            // Check if the click happened outside the div.
            if (!this.$refs.templateContainer.contains(event.target)) {
                this.updateData();
            }
        },
        updateData() {
            let returnData = [];
            this.listData.forEach((item, index) => {
                let itemDisplay = item.display.trim();
                let itemKey = item.new
                    ? itemDisplay.replace(/\s+/g, '_').toLowerCase()
                    : item.key;

                if (itemDisplay === '') {
                    return;
                }

                // Check to make sure there are no unique keys
                let uniqueKey = true;
                let itemIndex = null;
                do {
                    uniqueKey = true;

                    this.listData.forEach((checkItem, checkIndex) => {
                        if (checkIndex !== index) {
                            let checkKey = checkItem.key;
                            let itemKeyWithIndex =
                                itemIndex === null
                                    ? itemKey
                                    : itemKey + '_' + itemIndex.toString();

                            if (checkKey === itemKeyWithIndex) {
                                uniqueKey = false;
                            }
                        }
                    });

                    if (!uniqueKey) {
                        if (itemIndex === null) {
                            itemIndex = 0;
                        } else {
                            itemIndex++;
                        }
                    }
                } while (!uniqueKey);

                if (itemIndex !== null) {
                    itemKey += '_' + itemIndex.toString();
                }

                item.key = itemKey;

                // Just use the display as the key for now
                const newItem = { key: itemKey, display: itemDisplay };
                returnData.push(newItem);
            });

            this.$emit('list-data-changed', returnData);
        },
    },
};
</script>
