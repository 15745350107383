<template>
    <div id="add_field_container" class="container-fluid">
        <form id="customform">
            <h4 class="margin-top-20">
                Add a New Field
            </h4>
            <fieldset>
                <div class="row">
                    <template v-for="companyLanguage in companyLanguages" :key="companyLanguage.key">
                        <div class="col-sm-3 margin-top-05">
                            <label :for="companyLanguage.key" class="control-label">
                                <span v-if="companyLanguage.key === 'EN' && language !== 'EN'" style="vertical-align: middle">Internal </span>
                                <span v-html="companyLanguage.label"></span>
                            </label>
                            <input type="text" v-model="names[companyLanguage.key]" :class="getAddFieldClass(companyLanguage.key)" :placeholder="`Field Name ${companyLanguage.key}`" />
                        </div>
                    </template>

                    <div class="col-sm-3 margin-top-05">
                        <label for="type" class="control-label">Field Type</label>
                        <select v-model="fieldType" name="type" class="form-control required" placeholder="Field Type" data-length="3000">
                            <template v-for="option in fieldOptions" :key="option.key">
                                <option :value="option.key">{{ option.label }}</option>
                            </template>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 margin-top-10">
                        <a @click="addField" class="btn-primary btn">Add</a>
                        <span v-if="addFieldStatusSuccess !== null" :class="addFieldStatusSuccess ? 'text-success' : 'text-danger'">
                            &nbsp;{{ addFieldStatusMessage }}
                        </span>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        props: ['id', 'doption', 'language', 'companyLanguages'],
        data() {
            const fieldOptions = [
                { key: 'text', label: 'Text' },
                { key: 'textarea', label: 'Text Area'},
                { key: 'link', label: 'URL'},
                { key: 'phone', label: 'Phone'},
                { key: 'mobile', label: 'Mobile'}
            ];

            const namesMap = {};
            this.companyLanguages.forEach((companyLanguage) => {
                namesMap[companyLanguage.key] = '';
            });

            return {
                addFieldStatusSuccess: null,
                addFieldStatusMessage: '',
                fieldType: fieldOptions[0].key,
                fieldOptions,
                names: namesMap,
                initialNames: Object.assign({}, namesMap)
            };
        },
        methods: {
            getAddFieldClass(languageKey) {
                return ['EN', this.language].indexOf(languageKey) !== -1 ? 'form-control required' : 'form-control';
            },
            addField() {
                this.addFieldStatusSuccess = null;

                let namesMap = {};
                this.companyLanguages.forEach((companyLanguage) => {
                    namesMap[companyLanguage.key] = this.names[companyLanguage.key];
                });

                var self = this;
                site.addField(this.id, this.doption, this.fieldType, namesMap).then((response) => {
                    if (response.success) {
                        self.addFieldStatusSuccess = true;
                        self.addFieldStatusMessage = response.message;

                        self.$emit('field-added', response);

                        self.names = Object.assign({}, self.names, self.initialNames);
                        setTimeout(() => {
                            self.addFieldStatusSuccess = null;
                            self.addFieldStatusMessage = '';
                        }, 5000);
                    } else {
                        //200-error from server (success === false)
                        return Promise.reject(response);
                    }
                }).catch(res => {
                    const errMsgText = res.error_message ?? res.message ?? "There was an error communicating with the backend.";
                    self.addFieldStatusSuccess = false;
                    self.addFieldStatusMessage = errMsgText;
                });
            }
        }
    }
</script>
